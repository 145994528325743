import React from 'react'
import { Accordion } from 'react-bootstrap'

const AccordionContainer = (props) => {
  return (
    <div className='accordion-container'>
      <Accordion onSelect={(item)=>props.accordionClicked(item)}>

        {props.list.map((accordion, index) => {
          return (
            <Accordion.Item eventKey={index} key={index} >
              <Accordion.Header className='bg-white'>{accordion.faqQuestion}</Accordion.Header>
              {Array.isArray(accordion.faqResponse) ? (

                <Accordion.Body>
                  {accordion.faqResponse.map((item) => {
                    return <div className='d-flex flex-row justify-content-between'><p>{item}</p><button className="accordion-edit" onClick={() => props.onEditPress()}>
                    Edit
                </button></div>;
                  })}
                </Accordion.Body>
              ) : (
                <Accordion.Body>
                    {(accordion.faqResponse === undefined ||accordion.faqResponse == "") && accordion.faqQuestion === 'Postcode'  ? <div className='d-flex flex-row justify-content-between'><p>{'Not provided'}</p><button className="accordion-edit" onClick={() => props.onEditPress()}>
                    Edit
                </button></div>: <div className='d-flex flex-row justify-content-between'><p dangerouslySetInnerHTML={{__html: accordion.faqResponse}} /></div> }
                    
                </Accordion.Body>

              )}
            </Accordion.Item>
          )
        })}
      </Accordion>
    </div>
  )
}

export default AccordionContainer

