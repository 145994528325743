import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import RadioField from '../radioField'
import QuestionTitle from '../questionTitle'
import ErrorMessage from '../errorMessage'
import QuestionErrorMessage from './questionErrorMessage'
import GetHelpSection from '../general/getHelp'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomCheckBox from '../general/customCheckbox'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOptions, resetFetchOptions } from '../../screens/questionSet/store/actions'
import { setData } from '../../utils/storage'
import Loader from '../general/loader'
import ReactGA from 'react-ga4'

const QuestionForm = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const actionOptions = useSelector((state) => state.options);
  const [actionPlanOptions, setActionPlanOptions] = useState([]);

  const [unConditionQuestion, setUnConditionQuestion] = useState([]);
  const [conditionalQuestionList, setConditionalQuestionList] = useState([]);
  const [questionErrorMessage, setQuestionErrorMessage] = useState();
  const [questionNegative, setQuestionNegative] = useState(false);
  const [cardOptions, setCardOptions] = useState([]); // To store the selected options for the card component
  const [staticQuestion, setStaticQuestion] = useState([{
    "questionId": "steps_taken",
    "questionTitle": "Have you tried any of the following already?",
    "questionShortTitle": "Previously tried",
    "questionType": "Checkbox",
    "didYouKnow": "By law, your landlord must take your complaint seriously. They can not punish you in any way for making a complaint.",
    "whyAreWeAsking": "How you can escalate your complaint depends on whether you are a private tenant or a council or housing association tenant.",
    "isConditionalQuestion": false,
    "questionResponses": actionPlanOptions,
  }, {
    "questionId": "postcode",
    "questionTitle": "Please enter your postcode",
    "questionShortTitle": "Postcode",
    "questionType": "postcode",
    "didYouKnow": "Every local authority has a team that can investigate complaints about housing conditions and harassment.",
    "whyAreWeAsking": "How you can escalate your complaint depends on whether you are a private tenant or a council or housing association tenant.",
    "isConditionalQuestion": false,
    "questionResponses": [],
  }]);
  const [loader, setLoader] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [questionHistory, setQuestionHistory] = useState([]);
  const [questionStack, setQuestionStack] = useState([]);
  const [selectedResponses, setSelectedResponses] = useState([]);
  const [textResponse, setTextResponse] = useState('');
  const [formResponses, setFormResponses] = useState({});
  const [postCodeError, setPostCodeError] = useState(false);
  const [bottomErrorMsg, setBottomErrorMsg] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(1); // State to track current question index
  const [totalQuestions, setTotalQuestions] = useState(0); // State to track total questions count
  const [isStepstaken,setIsStepsTaken] = useState(false);
  // Separate the conditional and unconditional questions
  useEffect(() => {
    
    if(props.formResponseData != undefined  &&props.formResponseData != null)
    {
    if(Object.keys(props.formResponseData).length>0)
    {
      //.  console.log("data inside formresponsedData:-",props.formResponseData);
      setFormResponses(props.formResponseData);
      setTextResponse(props.formResponseData['postcode']);
    }
  }
    const primary = props.question?.filter((item) => item.isConditionalQuestion === false);
    setUnConditionQuestion(primary);

    const secondary = props.question?.filter((item) => item.isConditionalQuestion === true);
    setConditionalQuestionList(secondary);

    const alertUser = (e) => {
      e.preventDefault();
      alert("the data will be lost");
    };

    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };

  }, [props.question]);

 

  // Once the unconditional questions are set, combine them with static questions and render the first question
  useEffect(() => {
    const setAllQuestions = async() => {
       // assign static question based on tha flag sent by API response if all flag are false then only render dynamic question.
       const combinedQuestions = props.actionPlanQuestion && props.postCode ?[...unConditionQuestion, ...staticQuestion] : props.actionPlanQuestion ?  [...unConditionQuestion,staticQuestion[0]] : props.postCode ? [...unConditionQuestion,staticQuestion[1]] : unConditionQuestion;
      
       let question = props?.questionID != null && props?.questionID != "" ? combinedQuestions.filter(item => item.questionId == props?.questionID) : combinedQuestions;
       if(question.length <=0 && props?.questionID !== "")
       {
         question = props?.questionID != null && props?.questionID != "" ? conditionalQuestionList.filter(item => item.questionId == props?.questionID) : [];
       }
       if(props?.questionID != null   && props?.questionID != "" || props?.formResponseData != null && Object.keys(props?.formResponseData).length>0)
       {
        console.log("data inside:-",actionOptions.length);
         if (props?.questionID === 'steps_taken') {
           setIsStepsTaken(true);
           await getAlreadyTakenSteps(true)
         }else if(actionPlanOptions.length === undefined || actionPlanOptions.length === 0 ){
          setIsStepsTaken(false);
          await getAlreadyTakenSteps(false);
         }
         let responses = Object.values(formResponses).flat();
        
         setSelectedResponses(responses);
        
        let index =responses.findIndex(responseId => question[0].questionResponses.find(
         (response) => response.responseId === responseId && response.isValidResponse
       ));
       if(index === -1)
       {
        index = Object.keys(formResponses).flat ().findIndex(responseId => responseId === props.questionID);
        if(index === -1 && props?.questionID == 'postcode')
        {
          index = Object.keys(formResponses).flat ().length;
        }
       }
       
       let totalQuestions = [...props?.question,...staticQuestion];
       
        // Find the index of the target question
       let targetIndex = totalQuestions.findIndex(_question => _question.questionId === question[0].questionId);
         // Slice the array up to the target index
         let result = totalQuestions.slice(0, targetIndex).filter(q => formResponses.hasOwnProperty(q.questionId));
        
         setQuestionHistory(result);
         setQuestionIndex(index+1);
       }
       
       setCurrentQuestion(question[0]);
       props.noticeDescription(question[0].didYouKnow, question[0].whyAreWeAsking);
       
       let filteredQuestions = []
       if(props.questionID !== null && props.questionID !== "")
       {
         let questionndex = combinedQuestions.findIndex(q => q.questionId == question[0].questionId);
         let _questions = combinedQuestions;
         
         if(questionndex == -1)
         {
           questionndex = combinedQuestions.findIndex(obj=> {
             
             let temp =  obj.questionResponses.some(_resp => _resp.conditionalQuestionId == props?.questionID )
             
             return temp;
           });
           if(questionndex == -1)
           {
             
             questionndex = conditionalQuestionList.findIndex(obj =>{
               let temp =  obj.questionResponses.some(_resp => _resp.conditionalQuestionId == props?.questionID )
             
             return temp;
             })
             if (questionndex != -1)
             {
               
               let _condQuestionId = conditionalQuestionList[questionndex];
               
               questionndex = questionndex = combinedQuestions.findIndex(obj=> {
             
                 let temp =  obj.questionResponses.some(_resp => _resp.conditionalQuestionId == _condQuestionId.questionId )
                 
                 return temp;
               });
               
             }
           }
            filteredQuestions = _questions.slice(questionndex + 1,combinedQuestions.length+1);
         }else
           filteredQuestions = _questions.slice(questionndex + 1,combinedQuestions.length+1);
       } else {
           filteredQuestions = combinedQuestions.filter(q => q.questionId !== question[0].questionId);
       }
       setQuestionStack(filteredQuestions);
       // Initialize total questions count when questions are loaded
       const initialTotalQuestions = (props.formResponseData !== undefined || props.formResponseData != null )&& Object.keys(formResponses).length > 0 ? Object.keys(formResponses).length : combinedQuestions.length;
      
       const isPostcodePresent = formResponses.hasOwnProperty('postcode');
      
       if((props.formResponseData !== undefined && props.formResponseData != null) &&!isPostcodePresent && props.postCode )
       {
          
         setTotalQuestions(initialTotalQuestions + 1);
       }else 
         setTotalQuestions(initialTotalQuestions);
    }
   
    if (unConditionQuestion.length > 0) {
    
     setAllQuestions();
      
    }

  }, [unConditionQuestion]);

  // Handle fetching action options and updating state
  useEffect(() => {
    //.  console.log("data inside selectedResponses 3 :-");
    if (actionOptions.isSuccess && actionOptions.data !== null) {
      if (actionOptions.data.success.data.actionSteps.length === 0) {

        setActionPlanOptions([]);
      } else {
        const options = actionOptions.data.success.data.actionSteps.map(item => ({
          "responseId": item.stepId,
          "responseCopy": item.stepTitle,
          "isValidResponse": true,
          "invalidResponseMessage": "",
          "conditionalQuestionId": "",
          "externalSites": []
        }));
        options.push({
          "responseId": 'a98db976-3kwl-8xp1-lz94-kjf0bma5pez8',
          "responseCopy": "I have not tried any of these",
          "isValidResponse": true,
          "invalidResponseMessage": "",
          "conditionalQuestionId": "",
          "externalSites": []
        })
        setActionPlanOptions(options);
      }
      setLoader(false);
      dispatch(resetFetchOptions());
    } else if (actionOptions.isError) {
      dispatch(resetFetchOptions());
    }
  }, [actionOptions, dispatch]);

  // Update static questions with new action plan options
  useEffect(() => {
    //.  console.log("inside Update static questions with new action plan options");
    setStaticQuestion((prevStaticQuestions) => {
      const updatedQuestions = prevStaticQuestions.map((question) => {
        
        if (question.questionId === 'steps_taken') {
          return { ...question, questionResponses: actionPlanOptions };
        }
        if(formResponses.hasOwnProperty('steps_taken'))
        {
            const updatedStepsTaken = formResponses['steps_taken']?.filter(stepId => 
            actionPlanOptions.some(option => option.responseId === stepId)
            );
           formResponses['steps_taken'] = updatedStepsTaken;
          //  setFormResponses(formResponses);
        }
        return question;
      });
      if(isStepstaken)
        setCurrentQuestion(updatedQuestions[0]);
      return updatedQuestions;
    });
  }, [actionPlanOptions]);

  useEffect(() => {
    
  }, [selectedResponses])

  useEffect(() =>{
    
  },[questionHistory])

  // useEffect(() => {},[textResponse]);
  const handleResponse = (questionId, responseId, isRadio) => {
    let isResponsePresent = false;
    
    if (isRadio) {
      setSelectedResponses([responseId]);
      setFormResponses((prevResponses) => {
        // Remove any previously selected conditional question and its responses
        const updatedResponses = { ...prevResponses };
        currentQuestion.questionResponses.forEach((response) => {
          if (response.conditionalQuestionId && updatedResponses[response.conditionalQuestionId]) {

            const conditionalQuestion = conditionalQuestionList.find(q => q.questionId === response.conditionalQuestionId);
            if (conditionalQuestion) {
              
              const conditionalResponse = conditionalQuestion.questionResponses.find(r => formResponses.hasOwnProperty(r.conditionalQuestionId));
              if (conditionalResponse && conditionalResponse.conditionalQuestionId) {
                setTotalQuestions(totalQuestions-2);
              delete updatedResponses[conditionalResponse.conditionalQuestionId];
              setSelectedResponses((prevSelectedResponses) =>
                prevSelectedResponses.filter((id) => id !== conditionalResponse.conditionalQuestionId)
              );
              }
            }
            delete updatedResponses[response.conditionalQuestionId];
            setSelectedResponses((prevSelectedResponses) =>
              prevSelectedResponses.filter((id) => id !== response.conditionalQuestionId)
            );
            
          }
        });

        return {
          ...updatedResponses,
          [questionId]: [responseId],
        };
      });
      const selectedResponse = currentQuestion.questionResponses.find((obj) => obj.responseId === responseId);
      if (selectedResponse.responseCopy == "No") {
        setBottomErrorMsg(true)
        setQuestionNegative(false)
      } else {
        setBottomErrorMsg(false)
        setQuestionErrorMessage(selectedResponse.invalidResponseMessage);
        setQuestionNegative(!selectedResponse.isValidResponse);
      }

      setCardOptions(selectedResponse.externalSites);
    } else {
      
      let newSelectedResponses = selectedResponses.includes(responseId)
        ? selectedResponses.filter((id) => id !== responseId)
        :responseId=="a98db976-3kwl-8xp1-lz94-kjf0bma5pez8" ? [responseId]: [...selectedResponses, responseId];
        if (responseId!="a98db976-3kwl-8xp1-lz94-kjf0bma5pez8") {
          
          newSelectedResponses =  newSelectedResponses.filter((id) => id !== 'a98db976-3kwl-8xp1-lz94-kjf0bma5pez8');
        }
      
      setSelectedResponses(newSelectedResponses);

      isResponsePresent = selectedResponses.includes(responseId);
      setFormResponses((prevResponses) => {
        let currentResponses = prevResponses[questionId] || [];
        // if the reponse already contains the responseID of static option then remove it if other option are selected 
        if(currentResponses.includes("a98db976-3kwl-8xp1-lz94-kjf0bma5pez8") && responseId!="a98db976-3kwl-8xp1-lz94-kjf0bma5pez8")
        {
          
          currentResponses = currentResponses.filter((id) => id !== 'a98db976-3kwl-8xp1-lz94-kjf0bma5pez8');
        }
       
        return {
          ...prevResponses,
          [questionId]: currentResponses.includes(responseId)
            ? currentResponses.filter((id) => (id !== responseId))
            : responseId=="a98db976-3kwl-8xp1-lz94-kjf0bma5pez8" ? [responseId]:[...currentResponses, responseId],
        };
      });
      // Determine if any selected response is invalid
      const selectedResponsesDetails = newSelectedResponses.map((id) =>
        currentQuestion.questionResponses.find((obj) => obj.responseId === id)
      );
      const isAnyResponseInvalid = selectedResponsesDetails.some(
        (response) => response && !response.isValidResponse
      );

      // Determine the error messages and card options
      let errorMessages = [];
      let externalLinks = [];
      selectedResponsesDetails.forEach((response) => {
        if (response && !response.isValidResponse) {
          errorMessages.push(response.invalidResponseMessage);
          externalLinks = [...externalLinks, ...(response.externalSites || [])];
        }
      });

      setQuestionErrorMessage(errorMessages);
      setQuestionNegative(isAnyResponseInvalid);
      setCardOptions(externalLinks);

    }
  };

  const handleNext = async () => {
    if (currentQuestion.questionId === 'steps_taken' && selectedResponses.length === 0) {
      setFormResponses((prevResponses) => ({
        ...prevResponses,
        [currentQuestion.questionId]: [],
      }));
      if(!props.postCode)
      {
        submitData()
      }
    } else if (currentQuestion.questionId === 'postcode') {
      submitData();
      return;
    }else if(totalQuestions == questionIndex){
      submitData()
    }

    const selectedResponseObj = currentQuestion.questionResponses.find((response) =>
      selectedResponses.includes(response.responseId)
    );

    if (selectedResponseObj && selectedResponseObj.conditionalQuestionId) {
      const nextConditionalQuestion = conditionalQuestionList.find(
        (q) => q.questionId === selectedResponseObj.conditionalQuestionId
      );
      if (nextConditionalQuestion) {
        
        setQuestionHistory((prevHistory) => [...prevHistory, currentQuestion]);
        setCurrentQuestion(nextConditionalQuestion);
        const isResponsePresent = formResponses[nextConditionalQuestion.questionId];
        props.noticeDescription(nextConditionalQuestion.didYouKnow, nextConditionalQuestion.whyAreWeAsking);
        setSelectedResponses(isResponsePresent != undefined ? isResponsePresent : []);
        setQuestionIndex((prevIndex) => prevIndex + 1); // Increment question index
        
        if(isResponsePresent == undefined)
          setTotalQuestions((prevTotal) => prevTotal + 1); // Increment total questions count
        if (isResponsePresent) {
          const { isAnyResponseInvalid, errorMessages, externalLinks } = checkResponses(nextConditionalQuestion, isResponsePresent);
          setQuestionErrorMessage(errorMessages);
          setQuestionNegative(isAnyResponseInvalid);
          setCardOptions(externalLinks);
        }
        return;
      }
    }

    if (questionStack.length > 0) {
      if (questionStack[0].questionId === 'steps_taken') {
        setIsStepsTaken(true);
       await getAlreadyTakenSteps(true)
      }
      setQuestionHistory((prevHistory) => {
        if (!prevHistory.some(q => q.questionId === currentQuestion.questionId)) {
          return [...prevHistory, currentQuestion];
        }
        return prevHistory;
      });
      setCurrentQuestion(questionStack[0]);
      const isResponsePresent = formResponses[questionStack[0].questionId];
      props.noticeDescription(questionStack[0].didYouKnow, questionStack[0].whyAreWeAsking);
      setSelectedResponses(isResponsePresent != undefined ? isResponsePresent : []);
      setQuestionStack(questionStack.slice(1));
      setQuestionIndex((prevIndex) => prevIndex + 1); // Increment question index

      if (isResponsePresent && questionStack[0].questionId !== 'postcode') {
        const { isAnyResponseInvalid, errorMessages, externalLinks } = checkResponses(questionStack[0], isResponsePresent);
        setQuestionErrorMessage(errorMessages);
        setQuestionNegative(isAnyResponseInvalid);
        setCardOptions(externalLinks);
      }
    } else {
      setCurrentQuestion(null);
      setSelectedResponses([]);
    }

    ReactGA.event({
      category: "Question",
      action: 'Click',
      params: {
        questionId: 'abcc',
        response: 'abc',       // Parameter for the response value
        value: 'abc', 
      }
    })

    const appNameObject = {
      name: 'MyApp',
      version: '1.0',
      platform: 'web'
  };

    

  };

  const getAlreadyTakenSteps = async(isloaderflag)=>{
    setLoader(isloaderflag);
    const allPreviousAnswers = Object.entries(formResponses)
      .filter(([key]) => key !== 'steps_taken' && key !== 'postcode')
      .map(([, value]) => value)
      .flat();
    await dispatch(fetchOptions(allPreviousAnswers));
  }

  const checkResponses = (question, selectedResponses) => {
    const selectedResponsesDetails = selectedResponses.map((id) =>
      question.questionResponses.find((obj) => obj.responseId === id)
    );
    const isAnyResponseInvalid = selectedResponsesDetails.some(
      (response) => response && !response.isValidResponse
    );

    let errorMessages = [];
    let externalLinks = [];
    selectedResponsesDetails.forEach((response) => {
      if (response && !response.isValidResponse) {
        errorMessages.push(response.invalidResponseMessage);
        externalLinks = [...externalLinks, ...(response.externalSites || [])];
      }
    });

    return {
      isAnyResponseInvalid,
      errorMessages: errorMessages.join(' '),
      externalLinks
    };
  };

  const handlePrevious = async() => {
    if (questionHistory.length > 0) {
      const prevHistory = [...questionHistory];
      const previousQuestion = prevHistory[prevHistory.length - 1];
      if (!currentQuestion.isConditionalQuestion) {
        setQuestionStack([currentQuestion, ...questionStack]);
      }
      // Adjust total questions count when going back
      if (currentQuestion.isConditionalQuestion) {
        setTotalQuestions((prevTotal) => prevTotal - 1); // Decrease total questions count for conditional questions
      }
      if(previousQuestion.questionId === 'steps_taken')
      {
        setIsStepsTaken(true);
        await getAlreadyTakenSteps(true)
      }
      setCurrentQuestion(previousQuestion);
      prevHistory.pop(); // Remove last questoion from history
      props.noticeDescription(previousQuestion.didYouKnow, previousQuestion.whyAreWeAsking);
      setQuestionHistory(prevHistory);
      const previousResponses = formResponses[previousQuestion.questionId] || [];
      setSelectedResponses(previousResponses);
      setQuestionIndex((prevIndex) => prevIndex - 1); // Increment question index
      setQuestionErrorMessage('');
      setQuestionNegative(false);
      setCardOptions([]);
    }
  };

  const submitData = () => {

    const { steps_taken, postcode, ...actionData } = formResponses;
    //.  console.log("data inside formresponses:-",formResponses);
    // Combine all questions into one array
    const allQuestions = [...unConditionQuestion, ...conditionalQuestionList, ...staticQuestion];

    // Iterate through formResponses and construct the desired array of objects
    const responsesArray = Object.keys(actionData).map((questionId) => {
      const question = allQuestions.find((q) => q.questionId === questionId);
      if (!question) return null;

      const questionShortTitle = question.questionShortTitle;
      const responses = actionData[questionId];

      const answerVal = responses.map((responseId) => {
        const response = question.questionResponses.find((r) => r.responseId === responseId);
        return response ? response.responseCopy : responseId;
      });
      return {
        faqQuestion: questionShortTitle,
        faqResponse: answerVal
      };
    }).filter((obj) => obj !== null);
    if(props.actionPlanQuestion)
    {
      //.  console.log("data inside responses stepstaken:-",staticQuestion[0].questionResponses);
    const answerVal = steps_taken?.map((responseId) => {
      const response = staticQuestion[0].questionResponses.find((r) => r.responseId === responseId);
      return response ? response.responseCopy : responseId;
    });
    responsesArray.push({
      faqQuestion: staticQuestion[0].questionShortTitle,
      faqResponse: answerVal
    });
    }
    if(props.postCode)
    {
      responsesArray.push({
        faqQuestion: staticQuestion[1].questionShortTitle,
        faqResponse: postcode
      });
    }
    setData("actionData", responsesArray);
    setData("payLoad", { responses: JSON.stringify(actionData), steps_taken: steps_taken?.length > 0 ? JSON.stringify(steps_taken) : JSON.stringify([]), postcode: postcode? postcode: '' })

    let obj = {};
    responsesArray.map((item) => {
      if (item.faqResponse !== undefined && item.faqResponse.length > 0 && item.faqQuestion !== 'Postcode') {
        obj[item.faqQuestion] = item.faqResponse.join();
      }
    })
    window.gtag('event', 'questions_answer', {
      'label': 'Questions set',
      'screen_name': 'Questions',
      'value': JSON.stringify(obj)
    });

    navigate("/get-advice/action-plan", { state: { prevPath: location.pathname ,actionData: formResponses} });
  };

  const isNextEnabled = !questionNegative && currentQuestion && (
    (currentQuestion.questionType === 'postcode' && textResponse != undefined && textResponse.trim().length > 0) || (currentQuestion.questionType !== 'postcode' &&
      selectedResponses.some((responseId) =>
        currentQuestion.questionResponses.find(
          (response) => response.responseId === responseId && response.isValidResponse
        )
      )) || currentQuestion.questionId === 'steps_taken'
  );

  const __renderFields = (questionType, questionInfo, item) => {
   
   
    switch (questionType) {
      case 'Radio':
        return (
          <RadioField
            questionId={questionInfo.questionId}
            id={item.responseId}
            label={item.responseCopy}
            checked={formResponses[questionInfo.questionId]?.includes(item.responseId) || false}
            name={questionInfo.questionId}
            radioChange={handleResponse}
            value={item.responseId}
            isNegative={!item.isValidResponse}
            errorMessage={item.invalidResponseMessage ? item.invalidResponseMessage : ""}
            cardsData={item.externalSites ? item.externalSites : ""}
            nextQuestion={item.conditionalQuestionId}
          />
        );
      case 'postcode':
        return (
          <div>
            <p>If you choose to share this, we can direct you to your local authority if necessary.</p>
            <input
              type="text"
              placeholder='Enter postcode'
              className='postcode'
              value={textResponse}
              onChange={(e) => {
                const postCodeRegex = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
                if (!postCodeRegex.test(e.target.value)) {
                  setPostCodeError(true);
                } else {
                  setPostCodeError(false);
                }
                setTextResponse(e.target.value);
                setFormResponses({ ...formResponses, ['postcode']: e.target.value });
              }}
            />
            {postCodeError && <p className='text-danger'>Please enter a valid postcode</p>}
          </div>
        );
      case 'Checkbox':
        return (
          <div>
            <CustomCheckBox
              key={1}
              type="question"
              selected={formResponses[currentQuestion.questionId]?.includes(item.responseId)}
              label={item.responseCopy}
              value={item.responseCopy}
              onClick={() => handleResponse(questionInfo.questionId, item.responseId, false)}
            />
          </div>
        );
      default:
        return null;
    }
  }


  return (
    <div className='content-wrapper'>
      <div className='bg-white start-questions'>
        <div className='pb-2 pb-md-5'>
          {currentQuestion ? (
            <>
              <QuestionTitle question={currentQuestion.questionTitle} questionCount={questionIndex} totalCount={totalQuestions} />
              <div className='start-description'>
                {currentQuestion?.questionType === 'Checkbox' && currentQuestion.questionResponses.length > 0 ? <p className='mb-3 text-14 grey' style={{ color: '#505A5F', fontWeight: 400 }}>Select as many as apply</p> : null}
                {loader ? <Loader message={"Loading options....."} /> : currentQuestion?.questionType != 'postcode' ? currentQuestion.questionResponses.length === 0 ? <p className='text-15'>No options available</p> : currentQuestion.questionResponses.map((labelItem) =>
                  __renderFields(currentQuestion.questionType, currentQuestion, labelItem)
                ) : __renderFields(currentQuestion.questionType, currentQuestion, '')}
              </div>
            </>
          ) : (
            <Loader message={"Loading....."} />
          )}
          {questionNegative && (
            <div className='pb-3 pb-md-5'>
              <QuestionErrorMessage errorMessage={questionErrorMessage} />
              {Array.isArray(cardOptions) && cardOptions.length > 0 && (
                <GetHelpSection color="bg-white" list={cardOptions} />
              )}
            </div>
          )}
          <div className='button-container'>
            <Button className='prev button' disabled={currentQuestion !== null && currentQuestion?.questionId == unConditionQuestion[0].questionId} onClick={handlePrevious}></Button>
            {currentQuestion?.questionId === 'postcode' && (
              <Button className='next skip button me-2' onClick={submitData}>Skip</Button>
            )}
            <Button
              className='next button'
              disabled={!isNextEnabled}
              onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      </div>
      {bottomErrorMsg ? (
        <ErrorMessage message="This service currently only operates in England." />
      ) : null}
    </div>
  );
};

export default QuestionForm;
