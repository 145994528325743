import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { emailTemplate, resetEmailTemplate } from '../../screens/generateLetter/store/actions';
import { useLocation } from 'react-router-dom';

const EmailModal = (props) => {

  const location = useLocation();
  const [email, setEmail] = useState('');
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const emailContent = useSelector((state) => state.email);
  const [emailMessage, setEmailMessage] = useState("");


  useEffect(() => {
    if (emailContent.isSuccess && emailContent.data !== null) {
      setEmailMessage(emailContent.data.success.message);
      dispatch(resetEmailTemplate())
    } else if (emailContent.isError) {
      dispatch(resetEmailTemplate())
    }
  }, [emailContent])

  const handleSubmit = (event) => {
    
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    let obj = {
      email: email,
      subject: 'Your Action Plan',
      action_plan: props.htmlContent,
    }


    if(obj.email !== "") {
      dispatch(emailTemplate(obj))

      if(location.pathname === '/get-advice/telephone-prompts' && email !== '') {
        window.gtag('event', 'email_telephone_prompt', {
        'label': 'Email Telephone prompt',
        'screen_name': 'Telephone prompt',
        'value': 'Email'
      });
      } else if(location.pathname === '/get-advice/generated-letter' && email !== '') {
        window.gtag('event', 'email_generated_letter', {
          'label': 'Email Generated Letter',
          'screen_name': 'Generated Letter',
          'value': 'Email'
        });
      } else if(location.pathname === '/get-advice/action-plan' && email !== '') {
        window.gtag('event', 'email_action_plan', {
          'label': 'Email Action Plan',
          'screen_name': 'Action Plan',
          'value': 'Email'
        });
      } else {
        return
      }
      
    }

    // Handle your form submission logic here
    // For example, you can send the email somewhere

    // Close the modal after submission
    // props.onHide();
  };

  const close = () => {
    props.onHide()
    setEmailMessage("");
    setValidated(false);
    setEmail("");
    dispatch(resetEmailTemplate())
  }

  const cancelEmail = () => {
    props.onHide()
    setEmailMessage("");
    setValidated(false);
    setEmail("");
    dispatch(resetEmailTemplate())
  }


  const __renderModalBody = (type) => {
    switch (type) {
      case 'letter-builder':
        return (
          <ul>
            <li>
              <p><b>Do not email directly to the addressee</b> from this website, as there will be advice and notes included in the email which are to support you, and not intended for the recipient. </p>
            </li>
            <li>
              <p>Once you receive your email you should send it in a new email to the desired addressee, so that they receive it from your email address. </p>
            </li>
            <li>
              <p>Attach any relevant supporting documentation before sending.</p>
            </li>
          </ul>
        );
        break;
      case "telephone":
        return (
          <ul>
            <li>
              <p>Keep your notes as a record of your telephone conversation, in case you want to reference what was discussed at a later date. </p>
            </li>
          </ul>
        )
        break;
      default:
        return
    }
  }

  return (
    <>

      <Modal show={props.show} onHide={() => close()} className='email-modal' centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.type === "telephone" && emailMessage === "" ? props.title :
              props.type === "letter-builder" && emailMessage === "" ? props.title :
                props.type === "action-plan" && emailMessage === "" ? props.title :
                  emailMessage !== "" ? "Thank you" : ""}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {emailMessage !== "" ? (
            <div>
              <h2 className='thanks-title'>We have sent your email, it may take a few minutes to arrive.</h2>
              <p className='thanks-description'> If you do not receive the email, try again. </p>
              <div className='d-flex justify-content-between'>
              <Button variant="primary" type="submit" className='cancel me-2' style={{paddingRight: "3rem"}} onClick={() => { setEmailMessage(""); setEmail("");setValidated(false) }}>
                Try again
              </Button>
              <Button variant="primary" type="submit" className='submit button without-arrow pr-5' style={{paddingRight: "3rem"}} onClick={() => { setEmailMessage(""); setEmail(""); props.onHide();setValidated(false) }}>
                Done
              </Button>
              </div>
            </div>
          ) : (
            <>
              {__renderModalBody(props.type)}

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="email">
                  <Form.Control
                    type="email"
                    placeholder="Type your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <p className='email-info mt-2 mb-0'>TDS does not store or use your email address for any other purpose.</p>
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email.
                  </Form.Control.Feedback>
                </Form.Group>
                <div className='d-flex mt-4 justify-content-between'>
                <Button variant="primary" type="button" className='cancel me-2' onClick={() => cancelEmail()}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" className='submit button'>
                  {emailContent.isFetching ? "Sending..." : "Send"}
                </Button>
                </div>
              </Form>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailModal;
