import React, { useEffect, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap';
import { AccordionContainer, Loader, RadioDropdown } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFaqData, resetFaqData } from './store/actions';
import { Helmet } from 'react-helmet';
import FontResizeWidget from '../../components/FontResizeWidget';

const FAQ = () => {

    const dispatch = useDispatch();
    const [faqTopicList, setFaqTopicList] = useState([]);
    const [faqQuestions, setFaqQuestions] = useState([]);
    const faqResult = useSelector((state) => state.faqList);


    useEffect(() => {
        dispatch(fetchFaqData());
    }, [])

    useEffect(() => {
        if (faqResult.isSuccess && faqResult.data !== null) {
            let data = faqResult.data.success.data.faqTopics;
            let questions = faqResult.data.success.data.faqs
            setFaqQuestions(questions);
            for (const topics in data) {
                let obj = {
                    title: data[topics],
                    slug: topics
                }
                setTopics((current) => [...current, obj]);
            }
            dispatch(resetFaqData())
        } else if (faqResult.error) {
            dispatch(resetFaqData())
        }
    }, [faqResult])


    const [topic, setTopics] = useState([]);
    const [activeTab, setActiveTab] = useState();
    const [questioneer, setQuestioneer] = useState();


    useEffect(() => {
        setActiveTab(Object.values(topic)[0]?.title)

        setQuestioneer(faqQuestions[Object.values(topic)[0]?.slug])

    }, [topic])

    useEffect(() => { }, [activeTab])

    const onTabChange = (title, slug) => {
        setActiveTab(title);
        setQuestioneer(faqQuestions[slug]);
    };

    useEffect(() => {
    }, [faqQuestions])
    return (

        <div className='faq'>
            <FontResizeWidget minSize={14} maxSize={32} step={2} defaultSize={16} />
            <Helmet>
                <title>FAQ | My Housing Gateway</title>
                <meta property="og:title" content="FAQ | My Housing Gateway" />
                <meta name="twitter:card" content="FAQ | My Housing Gateway" />
                <meta description='If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue.' />
            </Helmet>
            {faqResult.isFetching ? (
                <Loader message={"Loading....."} />
            ) : faqResult.isSuccess && !Array.isArray(faqQuestions) ? (
                <div className='container'>
                    <h2 className='mt-4 mt-md-5 text-title-28 darkGrey'>Frequently asked questions</h2>
                    <div className='d-flex justify-content-between flex-column flex-md-row faq-content'>
                        <div className='col-md-3 d-none d-md-block'>
                            <h3 className='text-sub-title-20 faq-topic-title'>Topics</h3>
                            {
                                Array.isArray(topic) && topic.length > 0 ? (
                                    <ul className='d-flex flex-column mt-4'>
                                        {
                                            topic.map((item, index) => {
                                                return (
                                                    <li className={activeTab === item.title ? 'flex-grow-1 alig-items-stretch mb-3 active' : 'flex-grow-1 alig-items-stretch mb-3'} key={index}><Button className='d-block bg-white text-sub-title black fw-normal' onClick={() => onTabChange(item.title, item.slug)}>{item.title}</Button></li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>
                        <RadioDropdown title={activeTab} options={topic} onChange={onTabChange} />

                        <div className='accordion-list-container col-md-8 bg-white p-md-4 mb-5'>
                            {
                                Array.isArray(questioneer) && questioneer.length > 0 ? (
                                    <AccordionContainer list={questioneer} accordionClicked={()=>{}}/>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            ) : faqResult.isSuccess && Array.isArray(faqQuestions) ?
                <div className='container'>
                    <p className='text-24 mt-4'>No data found</p>
                </div>
                : null}


        </div>
    )
}

export default FAQ
