import React, { useEffect, useState } from "react";
import {
	AccordionContainer,
	ActionPlanCards,
	Cards,
	DropdownContainer,
	EmailModal,
	GetHelpSection,
	Loader,
	QuestionErrorMessage,
	QuestionTitle,
	ShareModal,
} from "../../components";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";
import { getData, setData } from "../../utils/storage";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchActionPlanData,
	fetchCommonActionPlanData,
	resetActionPlanData,
	resetCommonActionPlanData,
} from "./store/actions";
import {
	EmailLogo,
	EmailTemplateBanner,
} from "../../config/images";
import { BaseUrl } from "../../config";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Helmet } from "react-helmet";
import FontResizeWidget from "../../components/FontResizeWidget";

const ActionPlan = ({ route }, props) => {

	const location = useLocation();
	const [questionData, setQuestionData] = useState([]);
	const [actionData,setActionData] = useState({});
	const [windowWidth, setWindowWidth] = useState("");
	const [showAccordion, setShowAccordion] = useState(true);
	const [actionSteps, setActionSteps] = useState([]);
	const [generalExternalSites, setGeneralExternalSites] = useState([]);
	const [generalCaseStudies, setGeneralCaseStudies] = useState([]);
	const [generalTopics, setGeneralTopics] = useState([]);
	const [shareEnable, setShareEnable] = useState(false);
	const [emailModal, setEmailModal] = useState(false);
	const [warning, setWarning] = useState([]);
	const [loader, setLoader] = useState(true);
	const [postCode, setPostCode] = useState("");
	const [isAllSteps,setIsAllSteps] = useState(false);
	// dropdown for the step by step and all steps
	const [dropdownVal, setDropdownVal] = useState([
		{
			id: "1",
			value: "Step-by-step",
		},
		{
			id: "2",
			value: "All steps",
		},
	]);

	const [stepOption, setStepOption] = useState("Step-by-step");
	const [stepNumber, setStepNumber] = useState(0);
	const [quesionIdToPass,setQuestionIdtoPass] = useState("");
	const commonProblemData = useSelector((state) => state.commonActionData);
	const generateActionPlan = useSelector((state) => state.actionPlanData);

	const dispatch = useDispatch();
	//.  console.log("questionData:-", questionData);
	useEffect(() => {
		//.  console.log(location, "location");

		if (
			location.state !== null &&
			location.state.prevPath !== undefined &&
			location.state.prevPath === "/"
		) {
			getData("actionId").then((res) => {
				if (res !== undefined) {
					dispatch(fetchCommonActionPlanData(res));
				} else {
					navigate("/");
				}
			});
			setData("location", location.state.prevPath);
		} else if (
			location.state !== null &&
			location.state.prevPath !== undefined &&
			location.state.prevPath === "/get-advice/questions"
		) {
			getData("payLoad")
				.then((res) => {
					//.  console.log("res2:-", res);
					if (res !== undefined) {
						let _actionData = JSON.parse(res.responses);

							_actionData['steps_taken'] = JSON.parse(res.steps_taken);
							_actionData['postcode'] = res.postcode;
						//.  console.log("actionData 2:-",_actionData);
						setActionData(_actionData);
						dispatch(fetchActionPlanData(res));
					} else {
						navigate(location.state.prevPath);
					}
				})
				.catch((error) => {
					//.  console.log(error);
				});

			getData("actionData")
				.then((res) => {
					let data = res;
					
					setQuestionData(data);
				})
				.catch((error) => {
					//.  console.log(error);
				});

			setData("location", location.state.prevPath);
		} else {
			getData("location")
				.then((res) => {
					if (res === "/") {
						getData("actionId").then((res) => {
							if (res !== undefined) {
								dispatch(fetchCommonActionPlanData(res));
							} else {
								navigate("/");
							}
						});
					} else {
						getData("payLoad")
							.then((res) => {
								//.  console.log("res2:-", res);
								if (res !== undefined) {
									let _actionData = JSON.parse(res.responses);
									
										_actionData['steps_taken'] = JSON.parse(res.steps_taken);
										_actionData['postcode'] = res.postcode;
										//.  console.log("actionData 3:-",_actionData);
										setActionData(_actionData);
									dispatch(fetchActionPlanData(res));
								} else {
									navigate("/get-advice/questions");
								}
							})
							.catch((error) => {
								//.  console.log(error);
							});

						getData("actionData")
							.then((res) => {
								let data = res;
								setQuestionData(res);
							})
							.catch((error) => {
								//.  console.log(error);
							});
					}
				})
				.catch((error) => {
					//.  console.log("data");
				});
		}
	}, []);



	useEffect(() => {
		// api call for the common problem Id

		const handleResize = () => {
			setTimeout(() => {
				setWindowWidth(window.outerWidth);
				if (window.outerWidth <= 992) {
					setShowAccordion(false);
				} else {
					setShowAccordion(true);
				}
			}, 100);
		};
		handleResize();

		window.addEventListener("orientationchange", handleResize);
		window.addEventListener("load", handleResize);

		// if (params.state !== null &&  params.state.id !== undefined) {
		// }

		return () => {
			window.removeEventListener("orientationchange", handleResize);
			window.addEventListener("load", handleResize);
		};
	}, []);

	// for common problem
	useEffect(() => {
		if (generateActionPlan.isSuccess && generateActionPlan.data !== null) {
			let tempData = generateActionPlan.data.success.data.actionSteps;

			let stepOneText = false;
			let stepTwoText = false;

			const data = tempData.map((item, index) => {
				if (item.alreadyTaken !== false) {
					return item;
				}

				if (stepOneText === false) {
					stepOneText = true;
					setStepNumber(index);
					return {
						...item,
						oneText:
							"Based on what you have told us, we recommend trying this step before escalating further.",
					};
				} else if (stepOneText === true && stepTwoText === false) {
					stepTwoText = true;
					return {
						...item,
						twoText:
							"If you try the previous step and it does not help, you may want to try the following.",
					};
				}

				return item;
			});
            let isAllSteps = data.filter((item)=> item.alreadyTaken == false);
			
			setIsAllSteps(isAllSteps);
			setActionSteps(data);
			setPostCode(generateActionPlan.data.success.data.postcode);
			setGeneralCaseStudies(
				generateActionPlan.data.success.data.generalCaseStudies
			);
			setGeneralExternalSites(
				generateActionPlan.data.success.data.generalExternalSites
			);
			setData(
				"generalExternalSites",
				generateActionPlan.data.success.data.generalExternalSites
			);
			setGeneralTopics(generateActionPlan.data.success.data.generalTopics);
			setWarning(generateActionPlan.data.success.data.warnings);
			setTimeout(() => {
				setLoader(false);
			}, [1500]);
			dispatch(resetActionPlanData());
		} else if (generateActionPlan.isError) {
			dispatch(resetActionPlanData());
		}
	}, [generateActionPlan]);

	// for generate action plan
	useEffect(() => {
		if (commonProblemData.isSuccess && commonProblemData.data !== null) {
			setActionSteps(commonProblemData.data.success.data.actionSteps);
			setPostCode(commonProblemData.data.success.data?.postcode);
			setGeneralCaseStudies(
				commonProblemData.data.success.data.generalCaseStudies
			);
			setGeneralExternalSites(
				commonProblemData.data.success.data.generalExternalSites
			);
			setGeneralTopics(commonProblemData.data.success.data.generalTopics);
			setTimeout(() => {
				setLoader(false);
			}, [1000]);
			dispatch(resetCommonActionPlanData());
		} else if (commonProblemData.isError) {
			dispatch(resetCommonActionPlanData());
		}
	}, [commonProblemData]);

	useEffect(() => {
		if (!stepOption) {
			const greenBorderElement = document.querySelector(".greenBorder");
			if (greenBorderElement) {
				const elementPosition = greenBorderElement.offsetTop - 50; // Subtract 50px from the top position
				window.scrollTo({
					top: elementPosition, // Scroll to the element's position with the offset
					behavior: "smooth", // Smooth scrolling
				});
			}
		}
	}, [stepOption]);

	useEffect(()=>{

	},[isAllSteps])

	

	const onDropDownChange = (value, id) => {
		if (value === "Step-by-step") {
			setStepOption(true);
		} else {
			setStepOption(false);
		}
	};

	const onNextClick = () => {
		setStepNumber(
			stepNumber >= actionSteps.length - 1 ? stepNumber : stepNumber + 1
		);
	};

	const onPrevClick = () => {
		setStepNumber(stepNumber <= 0 ? stepNumber : stepNumber - 1);
	};

	const navigate = useNavigate();

	const handleEdit = (isBottomEdit) => {
		getData("formData").then((item) => {
			
			getData("actionData")
				.then((items) => {

					navigate("/get-advice/questions", { state: { formContent: item, actionsDataContent: items ,actionData: actionData, questionId: isBottomEdit ? '': quesionIdToPass} })
					// navigate("/get-advice/questions");
				})
				.catch((error) => {
					 navigate("/get-advice/questions", { state: { formContent: item } })
					// navigate("/get-advice/questions");
				});
		});
	};

	const options = {
		filename: "using-function.pdf",
		page: {
			margin: 20,
		},
	};

	const getTargetElement = () => document.getElementById("action-plan");
	const LogoImage = `${BaseUrl}/email/Logo.png`;
	const BannerTemplage = `${BaseUrl}/email/template-banner.png`;

	const htmlContent = `<html>
    <head></head>
    <body style="background-color: #fff; font-family: Helvetica, Arial, sans-serif;">
        <div>
            <img width="100" style="width: 100%;" src="${BannerTemplage}" alt="banner" />
        </div>
        <table style="width: 95%; padding: 0 1.313rem; margin: 0 auto; display: block;">
            <tr style="display: block; width: 100%" >
                <td style="display: block; margin-top: 1.688rem;">
                    <img src="${LogoImage}" style="width: 10%; min-width: 7rem" alt="TDS Logo" />
                </td>
            </tr>
            <tr style="margin-top: 1.688rem; display: block;">
                <td style="display: block;">
                    <p style="min-width: 18.75rem; margin-bottom: 0;">Hello,</p>
                </td>
            </tr>
            <tr style="margin-top: 1.688rem; display: block;">
                <td style="display: block;">
                    <p style="min-width: 18.75rem; margin-bottom: 0;">Thank you for taking the time to visit our support website for people who have issues with their rental property.</p>
                </td>
            </tr>
            <tr style="margin-top: 1.688rem; display: block;">
                <td style="display: block;">
                    <p style="min-width: 18.75rem; margin-bottom: 0;">Here is a copy of your personalised action plan from <a href="${BaseUrl}" target="_blank" style="font-weight: bold; color: #000">TDS Charitable Foundation</a> based on the answers you provided to our questions. </p>
                </td>
            </tr>
            <tr style="margin-top: 1.688rem; display: block;">
                <td style="display: block;">
                    <p style="min-width: 18.75rem; margin-bottom: 0;">We suggest that you try your “recommended step” first before trying the steps that come after. This is because we have laid them out in order of escalation, and you may be sent back to a previous step if you escalate too quickly.</p>
                </td>
            </tr>
            <tr style="margin-top: 1.688rem; display: block;">
                <td style="display: block;">
                    <p style="min-width: 18.75rem; margin-bottom: 0;">The action plan below is based off the answers you provided about your situation:</p>
                </td>
            </tr>
        </table>
        ${Array.isArray(questionData) && questionData.length > 0
			? `
        <table style="width: 95%; margin: 0 auto; padding: 1.313rem; display: block;">
        <tbody style="display: block; width: 100%">
            ${questionData
				.map(
					(item) => `
                <tr style="display: flex;">
                    <td style="min-width: 9.375rem; min-height: 100%; width: 40%; padding: 0.63rem; display: inline-block; vertical-align: top; border: 0.063rem solid #D3D3D3;">
                        <p style="margin-bottom: 0;">${item.faqQuestion}</p>
                    </td>
                    <td style="min-width: 9.375rem; min-height: 100%; width: 60%; padding: 0.63rem; display: inline-block; vertical-align: top; border: 0.063rem solid #D3D3D3;">
                        <p style="margin-bottom: 0;">${item.faqResponse}</p>
                    </td>
                </tr>
            `
				)
				.join("")}
            </tbody>
        </table>`
			: ""
		}
        <table style="width: 95%; margin: 0 auto; padding: 1.313rem; display: block;">
            <tr>
                <td style="display: block; margin-bottom: 0.938rem">
                    <p style="margin-bottom: 0;">Has your situation changed or these details don’t look right?</p>
                </td>
            </tr>
            <tr>
                <td style="margin-bottom: 1.375rem; display: block;">
                <a href="${BaseUrl}/get-advice/" style="font-size: 1rem; display: inline-block; color: #000; padding: 0.63rem 1.5rem; text-decoration: none; font-weight: 700; background-color: #00DD97">Retake the questions</a>
                </td>
            </tr>
            <tr>
                <td>
                    <h2 style="font-size: 1.3rem;">Your action Plan</h2>
                </td>
            </tr>
        
        </table>
        ${Array.isArray(actionSteps) && actionSteps.length > 0
			? `
            <table style="width: 92%; margin: 0 auto; padding: 1.313rem; display: block; background-color: #FAFAFA;">
            <tr>
                <td>
                    <h1 style="font-size: 1.3rem; font-weight: 700;">Recommended steps in order of escalation</h1>
                </td>
            </tr>

                ${actionSteps
				.map((item, index) => {
					let embedSrc = "";
					if (item.embedCode) {
						const tempDiv = document.createElement("div");
						tempDiv.innerHTML = item.embedCode;
						const iframe = tempDiv.querySelector("iframe");
						if (iframe) {
							embedSrc = iframe.getAttribute("src");
						}
					}
					return `
                    <tr class="action-cards" style="padding: 2.563rem 2.813rem; border: 0.125rem solid #E7E6E9; border-radius: 0.313rem; display: block; margin-top: 1rem;">
                        <td>
                            ${item.alreadyTaken
							? `
                                <p style="color: #003078; font-size: 1rem; font-weight: 700;">You have already tried this step.</p>
                            `
							: item.oneText
								? `
                                <p style="color: #003078; font-size: 1rem; font-weight: 700;">${item.oneText}</p>
                            `
								: item.twoText
									? `
                                <p style="color: #003078; font-size: 1rem; font-weight: 700;">${item.twoText}</p>
                            `
									: ""
						}
                            ${item.stepTitle
							? `
                                <h3 style="font-size: 1.3rem; font-weight: 700; margin-bottom: 1rem;">${item.stepTitle}</h3>
                            `
							: ""
						}
                            ${item.stepBody
							? `
                                <p style="font-size: 1rem; margin-bottom: 1rem;">${item.stepBody}</p>
                            `
							: ""
						}
						${item?.localCouncilEnabled && item?.localCouncilData !== undefined && item?.localCouncilData !== null ?
							`<div>
								<p className='text-17' style=" marginBottom: '0 !important'; font-size: 1.063rem; line-height: 1.286rem; font-weight:400; ">${postCode != null ? 'Based on your postcode, your local council is:' : 'Find your local council:'}</p>
								<div style="display:flex !important; margin-top: 0 !important; flex-wrap: 'wrap'; ">
								<div style="border-left: 0.25rem solid #00DD97; margin-top: 0 !important ;margin-bottom: 1.5rem; margin-right: 2rem; flex-basis: 20%; padding: 0.75rem; border-radius: 0 0.313rem 0.313rem 0; flex-direction: 'column'; min-width: 30%;">
									<h4 style="font-size: 1.125rem; padding-top: 0; margin-top:0; margin-bottom:0.5rem; color: #3B3B3B; font-weight: 700;">${item.localCouncilData.council}</h4>
									<a href=${item.localCouncilData.link ? item.localCouncilData.link : "https://www.google.com"} target='_blank' style="color:#00DD97; font-size:1.25rem; line-height: 1.25rem;">Visit site</a>
								</div>
								</div>
							</div>`
						:""
						}
						${item?.adviceLocalEnabled && item?.adviceLocalData !== undefined && item?.adviceLocalData !== null ?
							`<div>
								<p className='text-17 mb-0' style="marginBottom: '0 !important'; font-size: 1.063rem; line-height: 1.286rem; font-weight:400; ">${postCode != null ? 'Based on your postcode, here are some local advice services:':'Find your local advice services:'}</p>
								<div style="display:flex !important; margin-top: 0 !important; flex-wrap: 'wrap'; ">
								<div style="border-left: 0.25rem solid #00DD97; margin-top: 0 !important ;margin-bottom: 1.5rem; margin-right: 2rem; flex-basis: 20%; padding: 0.75rem; border-radius: 0 0.313rem 0.313rem 0; flex-direction: 'column'; min-width: 30%;">
									<h4 style="font-size: 1.125rem; padding-top: 0; margin-top:0; margin-bottom:0.5rem; color: #3B3B3B; font-weight: 700;">${item?.adviceLocalData?.localCouncilData.council}</h4>
									<a href=${item?.adviceLocalData?.localCouncilData.link ? item?.adviceLocalData?.localCouncilData.link : "https://www.google.com"} target='_blank' style="color:#00DD97; font-size:1.25rem; line-height: 1.25rem;">Visit site</a>
								</div>
								</div>
								
							</div>` : ""
						
						}
                        ${item.letterTemplateEnabled && item.stepSubHeading
							? `
                                <h4 style="font-size: 1.3rem; margin-bottom: 1rem; font-weight: 700; border-top: 0.063rem solid #cbcbcb; padding-top: 1rem;">${item.stepSubHeading}</h4>
                            `
							: "" 
						}
                            ${item.letterTemplateEnabled && item.stepSubBody
							? `
                                <p style="font-size: 1rem; margin-bottom: 1rem;">${item.stepSubBody}</p>
                            `
							: "" 
							}
							
                            ${embedSrc
							? `
                                <a href="${embedSrc}" target="_blank" style="font-size: 1rem; display: inline-block; color: #000; padding: 0.63rem 1.5rem; text-decoration: none; font-weight: 700; background-color: #00DD97; margin-bottom:2rem">Watch the informational video</a>
                            `
							: ""
						}
						${Array.isArray(item.externalSites) && item.externalSites.length > 0 ?
							`
							
							 <p style="font-size: 1rem; margin-bottom: 1rem;">Find out more information here:</p>
							
							 <div style="display:flex !important; margin-top: 0 !important; flex-wrap: 'wrap'; ">
							 ${item.externalSites.map((_item) => {
								return `<div style="border-left: 0.25rem solid #00DD97; margin-top: 0 !important ;margin-bottom: 1.5rem; margin-right: 2rem; flex-basis: 20%; padding: 0.75rem; border-radius: 0 0.313rem 0.313rem 0; flex-direction: 'column"; min-width: 20%;>
									<h4 style="font-size: 1.125rem; padding-top: 0; margin-top:0; margin-bottom:0.5rem; color: #3B3B3B; font-weight: 700;">${_item.externalSiteTitle}</h4>
									<a href=${_item.externalSiteLink ? _item.externalSiteLink : "https://www.google.com"} target='_blank' style="color:#00DD97; font-size:1.25rem; line-height: 1.25rem;">Visit site</a>
								</div>`
							})
							}
							</div>
						`
							: ""}
						${item.letterTemplateEnabled ?
						`
						<a
								href=${item.letterTemplate}
								target="_blank"
								className="button without-arrow text-center"
								style="color: #000;font-size: 1.063rem;font-weight: 600;padding: 0.75rem 1.5rem;
								background-color: #00DD97;text-align:'center';line-height: 1.25rem;border: none;
								border-radius: 0.313rem;position: relative;">
								Download the letter template
							</a>
						`:""
						}
                        </td>
                    </tr>
                    `;
				})
				.join("")}
            </table>
        `
			: ""
		}
        <table style="width: 95%; padding: 0 1.313rem; margin: 2rem auto 3rem; display: block;">
        <tbody style="display: block; width: 100%">
            <tr style="display: block">
                <td>
                    <p>For more information and resources please <a href='https://www.tdsfoundation.org.uk' target="_blank" style="font-weight: bold; text-decoration: underline; color: #3d4852">visit our website.</a></p>
                </td>
            </tr>
            <tr style="display: block">
                <td>
                    <p>Best wishes,</p>
                </td>
            </tr>
            <tr style="display: block">
                <td>
                    <p><i>My Housing Gateway Team</i></p>
                </td>
            </tr>
            <tr style="display: block;">
            <td style="display: block; margin-top: 1.688rem;">
                <img src="${LogoImage}" style="width: 10%; min-width: 7rem;" />
            </td>
        </tr>
        </tbody>
        </table>
    </body>
</html>`;

	const printContent = `<html">
    <head>
        <style>
            body {
                background-color: #fff; /* Your desired background color */
                font-size: 2vw;
                font-family: Helvetica, arial, sans-serif
            }
            .your-class-name {
                background-color: #FAFAFA; /* Example background color for specific elements */
            }
            table {
                width: 95%;
                padding: 0 1.313rem;
                margin: 0 auto;
                display: block;
            }
            .action-cards {
                padding: 2.563rem 2.813rem;
                border: 0.125rem solid #E7E6E9;
                border-radius: 0.313rem;
                display: block;
                margin-top: 1.5rem;
                
            }
            /* Print-specific styles */
            @media print {
                body {
                    -webkit-print-color-adjust: exact; /* Ensures background colors are printed correctly in WebKit browsers */
                    print-color-adjust: exact; /* Ensures background colors are printed correctly in other browsers */
                    background-color: #fff; /* Your desired background color for print */
                }
                .your-class-name {
                    background-color: #FAFAFA !important; /* Ensures the background color is applied for print */
                }
                .action-cards {
                    background-color: #FAFAFA !important; /* Ensures the background color is applied for print */
                }
            }
        </style>
    </head>
    <body>
        <div>
            <img style="width: 100%;" src="${EmailTemplateBanner}" alt="banner" />
        </div>
        <table style="width: 95%; margin: 0 auto; padding: 1.313rem; display: block";>
            <tr style="margin-top: 1.188rem; display: block">
                <td style="margin: 1.688rem 0; display: block">
                    <img style="width: 20%"  src="${EmailLogo}" alt="Logo" />
                </td>
            </tr>
            <tr style="margin-top: 1.188rem;">
                <td>
                    <p style="min-width: 18.75rem; margin-bottom: 1rem">Hello,</p>
                </td>
            </tr>
            <tr style="margin-top: 1.188rem;">
                <td>
                    <p style="min-width: 18.75rem; margin-bottom: 1rem">Thank you for taking the time to visit our support website for people who have issues with their rental property.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="min-width: 18.75rem; margin-bottom: 1rem">We suggest that you try your “recommended step” first before trying the steps that come after. This is because we have laid them out in order of escalation, and you may be sent back to a previous step if you escalate too quickly.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="min-width: 18.75rem; margin-bottom: 1rem">The action plan below is based off the answers you provided about your situation:</p>
                </td>
            </tr>
        </table>
        ${Array.isArray(questionData) && questionData.length > 0
			? `
        <table style="width: 95%; margin: 0 auto; padding: 1.313rem; display: block;">
        <tbody style="display: block; width: 100%">
            ${questionData
				.map(
					(item) => `
                <tr style="display: flex; width:100%; vertical-align: top">
                    <td style="min-width: 9.375rem; min-height: 100%; width: 40%; padding: 0.63rem; display: inline-block; vertical-align: top; border: 0.063rem solid #D3D3D3;">
                        <p style="margin-bottom: 0;">${item.faqQuestion}</p>
                    </td>
                    <td style="min-width: 9.375rem; min-height: 100%; width: 60%; padding: 0.63rem; display: inline-block; vertical-align: top; border: 0.063rem solid #D3D3D3;">
                        <p style="margin-bottom: 0;">${item.faqResponse}</p>
                    </td>
                </tr>
            `
				)
				.join("")}
            </tbody>
        </table>`
			: ""
		}
        <table style="width: 95%; margin: 0 auto; padding: 1.313rem; display: block;">
            <tr>
                <td>
                    <p style="margin-top: 1rem">Has your situation changed or these details don’t look right?</p>
                </td>
            </tr>
            <tr>
                <td style="margint-top: 2rem; display: block">
                    <h3 style="margint-top: 2rem">Your action Plan</h3>
                </td>
            </tr>
           
        </table>
        ${Array.isArray(actionSteps) && actionSteps.length > 0
			? `
            <table style="width: 95%; margin: 0 auto; padding: 1.313rem; display: block; background-color: #FAFAFA;">
            <tr>
                <td>
                    <h1 style="font-size: 1.3rem; font-weight: 700; margin-top: 1rem">Recommended steps in order of escalation</h1>
                </td>
            </tr>
                ${actionSteps
				.map(
					(item, index) => `
                    <tr class="action-cards">
                        <td>
                            ${item.alreadyTaken
							? `
                                <p style="color: #003078;  font-weight: 700;">You have already tried this step.</p>
                            `
							: item.oneText
								? `
                                <p style="color: #003078;  font-weight: 700;">${item.oneText}</p>
                            `
								: item.twoText
									? `
                                <p style="color: #003078;  font-weight: 700;">${item.twoText}</p>
                            `
									: ""
						}
                            ${item.stepTitle
							? `
                                <h3 style=" font-weight: 700; margin-bottom: 1rem;">${item.stepTitle}</h3>
                            `
							: ""
						}
                            ${item.stepBody
							? `
                                <p style=" margin-bottom: 1rem;">${item.stepBody}</p>
                            `
							: ""
						}
                            ${item.stepSubHeading
							? `
                                <h4 style=" margin-bottom: 1rem; font-weight: 700; border-top: 0.063rem solid #cbcbcb; padding-top: 1rem;">${item.stepSubHeading}</h4>
                            `
							: ""
						}
                            ${item.stepSubBody
							? `
                                <p style=" margin-bottom: 1rem;">${item.stepSubBody}</p>
                            `
							: ""
						}
                           
                        </td>
                    </tr>
                `
				)
				.join("")}
            </table>
        `
			: ""
		}
       
    </body>
</html>`;

	const generatePDF = (htmlString) => {
		const pdf = new jsPDF("p", "mm", "a4"); // Portrait, mm, A4 size
		const iframe = document.createElement("iframe");
		iframe.style.visibility = "hidden"; // Hide iframe
		document.body.appendChild(iframe);

		const doc = iframe.contentDocument || iframe.contentWindow.document;
		doc.open();
		doc.write(htmlString);
		doc.close();

		const options = {
			scale: 2, // Adjust scale for better resolution
			useCORS: true, // Enable CORS for rendering external images
			logging: true, // Enable logging for debugging (optional)
		};

		const pdfWidth = pdf.internal.pageSize.getWidth();
		const pdfHeight = pdf.internal.pageSize.getHeight();

		let yPosition = 0;

		html2canvas(htmlString, options).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const imgWidth = pdfWidth;
			const imgHeight = (canvas.height * imgWidth) / canvas.width;

			let totalPages = Math.ceil(imgHeight / pdfHeight);
			let remainingHeight = imgHeight;
			let currentPosition = 0;

			while (remainingHeight > 0) {
				if (currentPosition > 0) {
					pdf.addPage();
					yPosition = -currentPosition * pdfHeight;
				}

				let heightOnPage = Math.min(pdfHeight, remainingHeight);

				// Adjust yPosition for the next page
				pdf.addImage(
					imgData,
					"PNG",
					0,
					yPosition,
					imgWidth,
					heightOnPage,
					"",
					"FAST"
				);

				remainingHeight -= heightOnPage;
				currentPosition++;

				// Check if there's more content to add
				if (remainingHeight > 0) {
					pdf.addPage(); // Add a new page for the remaining content
				}
			}

			pdf.save("action-plan-pdf.pdf");
			document.body.removeChild(iframe);
		});
	};

	const downloadPdf = () => {
		generatePDF(printContent);
	};

	const emailClick = () => {
		setEmailModal(true);
	};

	const print = () => {
		const printWindow = window.open("", "", "width=800,height=600");
		printWindow.document.write(printContent);
		printWindow.document.close();
		printWindow.document.title = "Generated-action-plan";
		printWindow.focus();

		const images = printWindow.document.images;
		const totalImages = images.length;
		let loadedImages = 0;

		if (totalImages === 0) {
			printWindow.print();
			printWindow.close();
		} else {
			for (let i = 0; i < totalImages; i++) {
				images[i].onload = () => {
					loadedImages++;
					if (loadedImages === totalImages) {
						printWindow.print();
						printWindow.close();
					}
				};
				images[i].onerror = () => {
					loadedImages++;
					if (loadedImages === totalImages) {
						printWindow.print();
						printWindow.close();
					}
				};
			}
		}

		window.gtag("event", "print_action_plan", {
			label: "Print Action Plan",
			screen_name: "Action plan",
			value: "Print",
		});
	};

	const onAccordionClicked = (item) =>{
		
		//.  console.log("data inside usesate actionData", actionData);
		let data = Object.keys(actionData);
		//.  console.log(data);
		if(data[item] !== undefined){
		setQuestionIdtoPass(data[item]);
		}else{
			setQuestionIdtoPass("postcode");
		}
		
	}	
	return (
		<div className="d-flex flex-grow-1">
			<FontResizeWidget minSize={14} maxSize={32} step={2} defaultSize={16} />
			<Helmet>
				<title>{`${location.state !== null && location.state.title !== undefined
					? location.state.title + " |"
					: ""
					}  ${location.state !== null && location.state.dropdown !== undefined
						? location.state.dropdown + " |"
						: ""
					}  ${location.state !== null && location.state.prevPath === "/"
						? "Common problems"
						: "Get advice"
					} | Action Plan | My Housing Gateway`}</title>
				<meta
					property="og:title"
					content={`${location.state !== null && location.state.title !== undefined
						? location.state.title + " |"
						: ""
						}  ${location.state !== null && location.state.dropdown !== undefined
							? location.state.dropdown + " |"
							: ""
						}  ${location.state !== null && location.state.prevPath === "/"
							? "Common problems"
							: "Get advice"
						} | Action Plan | My Housing Gateway`}
				/>
				<meta
					name="twitter:card"
					content={`${location.state !== null && location.state.title !== undefined
						? location.state.title + " |"
						: ""
						}  ${location.state !== null && location.state.dropdown !== undefined
							? location.state.dropdown + " |"
							: ""
						}  ${location.state !== null && location.state.prevPath === "/"
							? "Common problems"
							: "Get advice"
						} | Action Plan | My Housing Gateway`}
				/>
				<meta description="If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue." />
			</Helmet>
			{commonProblemData.isFetching ||
				generateActionPlan.isFetching ||
				loader ? (
				<Loader message={"Loading your action plan..."} />
			) : commonProblemData.isSuccess || generateActionPlan.isSuccess ? (
				<div
					className="action-plan d-flex flex-grow-1 flex-column"
					id="action-plan"
				>
					<div className="d-flex justify-content-between container container-wrap flex-wrap">
						<div className="bg-white action-plan-container">
							<div className="d-flex justify-content-between">
								<h2 className="title-32">Your action plan</h2>
								<div className="d-none d-md-block">
									<button
										className="button without-arrow me-3"
										onClick={() => emailClick()}
									>
										Email
									</button>
									<button
										className="button without-arrow"
										onClick={() => print()}
									>
										Print
									</button>
								</div>
							</div>
							<p className="text-18 action-description">
								Based on what you have told us and tried so far, we recommend
								you try the following steps in order to get advice or solve your
								issue.
							</p>
							{/* // make this warning condition once pro question flow is finalised since this waring is dependent on the option selected flag will be sent by API for same */}
							{Array.isArray(warning) && warning.length > 0 ? (
								<>
									{warning.map((item) => {
										return (
											<QuestionErrorMessage
												title={item.warningTitle}
												errorMessage={item.warningDescription}
											/>
										);
									})}
								</>
							) : null}
							{actionSteps.length === 0 ? (
								<h3 className="text-24 mt-4">No action plan found</h3>
							) : null}
							
							{Array.isArray(actionSteps) && actionSteps.length > 0 ? (
								<>
									<div className="d-flex justify-content-between flex-column flex-md-row recommended">
										<h3 className="text-21">Recommended steps</h3>
										<div className="step-dropdown">
											<DropdownContainer
												title="View as"
												options={dropdownVal}
												onDropDownChange={onDropDownChange}
											/>
										</div>
									</div>
									{stepOption ? (
										<>
											{actionSteps.map((item, index) => {
												return index === stepNumber ? (
													<div className="action-card-container">
														{isAllSteps.length === 0 ?  <p className="action-info">Since you have already tried all the recommended steps, you may wish to seek further legal advice. Below is a breakdown of the steps you have taken so far.</p>  : item.alreadyTaken ? (
															<p className="action-info">
																You have already tried this step.
															</p>
														) : item.oneText ? (
															<p className="action-info">{item.oneText}</p>
														) : item.twoText ? (
															<p className="action-info">{item.twoText}</p>
														) : null}

														<ActionPlanCards
															cardData={item}
															onClick={downloadPdf}
															greenBorder={item.oneText ? true : false}
															postCode={postCode}
														/>
													</div>
												) : null;
											})}
											<div
												className={
													stepNumber === 0
														? "d-flex justify-content-end mt-4"
														: "d-flex justify-content-between mt-4"
												}
											>
												<button
													className={
														stepNumber === 0
															? "button without-arrow action-prev d-none"
															: "button without-arrow action-prev"
													}
													onClick={() => onPrevClick()}
												></button>
												<button
													className={
														stepNumber === actionSteps.length - 1
															? "button without-arrow action-next d-none"
															: "button without-arrow action-next"
													}
													onClick={() => onNextClick()}
												>
													Next step
												</button>
											</div>
										</>
									) : (
										<>
											{actionSteps.map((item, index) => {
												return (
													<div className="action-card-container">
														{isAllSteps.length === 0 ?  index == 0 ? <p className="action-info">Since you have already tried all the recommended steps, you may wish to seek further legal advice. Below is a breakdown of the steps you have taken so far.</p> : null : item.alreadyTaken ? (
															<p className="action-info">
																You have already tried this step.
															</p>
														) : item.oneText ? (
															<p className="action-info">{item.oneText}</p>
														) : item.twoText ? (
															<p className="action-info">{item.twoText}</p>
														) : null}
														<ActionPlanCards
															cardData={item}
															onClick={downloadPdf}
															greenBorder={item.oneText ? true : false}
															postCode={postCode}
														/>
													</div>
												);
											})}
										</>
									)}

									<div className="mt-5 d-flex d-md-none justify-content-between bottom-buttons">
										<button
											className="button without-arrow me-3"
											onClick={() => emailClick()}
										>
											Email
										</button>
										<button
											className="button without-arrow"
											onClick={() => print()}
										>
											Print
										</button>
									</div>
								</>
							) : null}
						</div>
						{Array.isArray(questionData) && questionData.length > 0 ? (
							<div className="action-accordion">
								<div>
									<div
										className={windowWidth <= 992 ? "accordion-list" : ""}
										onClick={() =>
											windowWidth <= 992
												? setShowAccordion(!showAccordion)
												: console.log("")
										}
									>
										<QuestionTitle question={"Your answers"} />
									</div>
									{showAccordion ? (
										<>
											<AccordionContainer list={questionData} accordionClicked={onAccordionClicked} onEditPress={()=>handleEdit(false)}/>
											<button className="edit" onClick={() => handleEdit(true)}>
												Edit answers
											</button>
										</>
									) : null}
								</div>
							</div>
						) : null}
					</div>
					<div className="container general-data-container">
						<div className="general-data">
							{Array.isArray(generalExternalSites) &&
								generalExternalSites.length > 0 ? (
								<>
									<h2 className="other-resource">Other helpful resources</h2>
									<GetHelpSection
										list={generalExternalSites}
										title={"Where can I get help?"}
										description={
											"If you need alternative help or advice, please visit one of these websites:"
										}
									/>
								</>
							) : null}

							{/* conditional handling for the case studies  */}
							{Array.isArray(generalCaseStudies) &&
								generalCaseStudies.length > 0 ? (
								<div className="card-container">
									<h3 className="title lightGrey">
										Other people in your situation
									</h3>
									<p className="text-normal lightGrey">
										For general information, browse by topic instead.
									</p>
									<ul className="d-flex case-list-container">
										{generalCaseStudies.map((caseItem) => {
											return (
												<Cards
													type="case"
													title={`${caseItem.caseStudyName}, ${caseItem.caseStudyLocation}`}
													description={caseItem.caseStudyTestimonial}
												/>
											);
										})}
									</ul>
								</div>
							) : null}

							{/* conditional handling for the topics card  */}
							{Array.isArray(generalTopics) && generalTopics.length > 0 ? (
								<div className="card-container topics-card">
									<h3 className="title lightGrey">Our topics</h3>
									<p className="text-normal lightGrey">
										For general information, browse by topic instead.
									</p>
									<ul className="d-flex flex-wrap">
										{generalTopics.map((topics) => {
											return (
												<Cards
													type="topic"
													title={topics.topicTitle}
													link={topics.topicSlug}
													target="_blank"
												/>
											);
										})}
									</ul>
								</div>
							) : null}
						</div>
					</div>
				</div>
			) : null}

			<ShareModal show={shareEnable} onHide={() => setShareEnable(false)} />
			{ }
			<EmailModal
				show={emailModal}
				onHide={() => setEmailModal(false)}
				htmlContent={htmlContent}
				type={"action-plan"}
				title={
					"Please provide your email address so we can send you the action plan"
				}
			/>
		</div>
	);
};

export default ActionPlan;
