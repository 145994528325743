import React, { useState, useEffect } from 'react';

const FontResizeWidget = ({ minSize = 12, maxSize = 32, step = 2, defaultSize = 16 }) => {
  const [fontSize, setFontSize] = useState(defaultSize);

  // Apply font size to the body element dynamically
  useEffect(() => {
    document.documentElement.style.fontSize = `${fontSize}px`;
  }, [fontSize]);

  // Handlers for resizing
  const increaseFontSize = () => {
    if (fontSize + step <= maxSize) {
      setFontSize(fontSize + step);
    }
  };

  const decreaseFontSize = () => {
    if (fontSize - step >= minSize) {
      setFontSize(fontSize - step);
    }
  };

  const resetFontSize = () => {
    setFontSize(defaultSize);
  };

  return (
    <div style={{ display: 'flex',
          gap: '10px',
          alignItems: 'center',
          position: 'absolute',
          top: '0px',
          zIndex: 1111,
          right: 0, }}>
      <button onClick={decreaseFontSize} aria-label="Decrease font size">
        {'A-'}
      </button>
      <button onClick={resetFontSize} aria-label="Reset font size">
        {'Reset'}
      </button>
      <button onClick={increaseFontSize} aria-label="Increase font size">
       {' A+'}
      </button>
    </div>
  );
};

export default FontResizeWidget;
