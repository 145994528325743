import React, { useEffect, useState } from 'react'
import { Breadcrumb, Cards, ErrorMessage, GetHelpSection, Loader, NoticeCard, QuestionTitle } from '../components'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHomepageData, resetHomepageData } from './homepage/store/actions'
import { getData } from '../utils/storage'
import { Helmet } from 'react-helmet'
import FontResizeWidget from '../components/FontResizeWidget'

const StartQuestion = () => {
  const [topicsData, setTopicData] = useState([]);
  const topicList = useSelector((state) => state.home);
  const dispatch = useDispatch();

  useEffect(() => {
    if (topicList.isSuccess && topicList.data !== null) {
      let topicContent = topicList.data.success.data.topics;
      setTopicData(topicContent)
      dispatch(resetHomepageData());
    } else if (topicList.isError) {
      dispatch(resetHomepageData());
    }



  }, [topicList])

  useEffect(() => {

    getData('homepageData').then((res) => {
      if (res !== undefined) {
        let topicContent = res.topics
        setTopicData(topicContent)
      } else {
        dispatch(fetchHomepageData());
      }

    }).catch((error) => console.log(error))

  }, [])


  const questionStart = () => {
    window.gtag('event', 'start_question', {
      'label': 'Start question',
      'screen_name': 'Get advice',
      'value': 'Start now'
    });
  }

  return (
    <div>
      <FontResizeWidget minSize={14} maxSize={32} step={2} defaultSize={16} />
      <Helmet>
        <title>Get advice | My Housing Gateway</title>
        <meta property="og:title" content="Get advice | My Housing Gateway" />
                <meta name="twitter:card" content="Get advice | My Housing Gateway" />
        <meta description='If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue.' />
      </Helmet>
      <div className='start-questions'>
        <div className='container '>
          <div className='col-12 col-md-8 mb-0 breadcrumb-container'>
            <Breadcrumb />
          </div>

          <div className='d-flex start justify-content-between flex-column flex-md-row'>
            <div className='col-12 col-md-8'>
              <div className='content-wrapper'>
                <div className='bg-white'>
                  <QuestionTitle question={"Need help with a specific issue with your rental property?"} />
                  <div className='start-description'>
                    <p className='text-18'>{'If you are facing an issue or problem with your rental property, share the details with us, and we will provide you with an action plan outlining the best steps to take based on what you have already tried.'} </p>
                    <h3 className='text-18 description-top fw-bold'>This is for people renting a property in England through:</h3>
                    <ul>
                      <li><p className='text-18'>a housing association</p></li>
                      <li><p className='text-18'>a council</p></li>
                      <li><p className='text-18'>a letting agent</p></li>
                      <li><p className='text-18'>a landlord directly</p></li>
                    </ul>
                    <p className='text-18 description-bottom fw-bold'>and who want to remain in their current property and continue their tenancy.</p>
                    <Link to="/get-advice/questions" className='button start-button' onClick={() => questionStart()}>Start now</Link>
                  </div>
                </div>
                <ErrorMessage message="Please note, this is not legal advice. If you are concerned about Section 21 or potential repercussions of raising a complaint, we recommend seeking additional advice before acting on the plan." link="https://advicelocal.uk/find-an-adviser" />
              </div>
            </div>
            <div className='col-12 col-md-3'>
              <NoticeCard title={"Did you know?"} description={"<p>If the Gateway is not right for you, search <a href='https://advicelocal.uk/find-an-adviser' target='_blank'>here</a> to find other services that might be more appropriate to your issue</p>"} />
            </div>
          </div>
        </div>
      </div>
      <div className='container start-topics'>
        {topicList.isFetching ? (
          <Loader message={"Loading....."} />
        ) : topicsData.length > 0 ? (

          <div className='card-container '>
            <p className='mb-0 text-18 fw-normal'>For general information, browse by topic instead</p>
            <ul className='d-flex justify-content-start'>
              {topicsData.map((item) => {
                return <Cards type="topic" title={item.topicTitle} link={item.topicSlug} />
              })}
            </ul>
            {/* <GetHelpSection description={"For general information, browse by topic instead"} /> */}
          </div>
        ) : null}

      </div>
    </div>
  )
}

export default StartQuestion
